import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h2: "h2",
    ..._provideComponents(),
    ...props.components
  }, {Button} = _components;
  if (!Button) _missingMdxReference("Button", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h2, {
      id: "size",
      children: _jsx(_components.a, {
        href: "#size",
        children: "Size"
      })
    }), "\n", _jsxs(_components.code, {
      preview: true,
      className: "language-jsx",
      code: `  <Button size="small">Small Button</Button>
  <Button size="medium">Medium Button</Button>
  <Button size="large">Large Button</Button>`,
      children: ["  ", _jsx(Button, {
        size: "small",
        children: "Small Button"
      }), _jsx(Button, {
        size: "medium",
        children: "Medium Button"
      }), _jsx(Button, {
        size: "large",
        children: "Large Button"
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
